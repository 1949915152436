<template>
  <div>
    <!--    导航栏-->
    <tabs @parentEvent="btn"></tabs>
    <!--   end 导航栏-->

    <!--        中间部分-->
    <div class="main">
      <!--      图片-->
      <div class="solution-banner-container">
        <div class="page-content"></div>
      </div>
      <!--      内容-->
      <div class="content">
        <div class="container">
          <ul class="time-vertical">
            <li
              v-for="(item, i) in news.slice(
                (currentPage - 1) * pagesize,
                currentPage * pagesize
              )"
              :key="i"
            >
              <a href="javascript:void(0);" @click="newsBtn(item.Id)" :key="i">
                <div style="color: #3c64da;">{{ item.ReleaseAt }}</div>
                <b>
                  <div style="color: #000; font-size: 20px;">
                    {{ item.Title }}
                  </div>
                </b>
                <div style="color: #aeaeae; font-size: 18px;">
                  {{ item.Brief }}
                </div>
              </a>
            </li>
          </ul>
        </div>

        <!-- <div class="new">
          <a
            href="javascript:void(0);"
            @click="newsBtn(item.Id)"
            class="new-item"
            v-for="(item, i) in news.slice(
              (currentPage - 1) * pagesize,
              currentPage * pagesize
            )"
            :key="i"
          >
            <img :src="item.Cover" alt="" />
            <div class="content2">
              <div class="data">{{ item.ReleaseAt }}</div>
              <div class="title">{{ item.Title }}</div>
              <div class="tags">
                <span
                  class="tag"
                  v-for="(item2, index) in item.tag"
                  :key="index"
                  >{{ item2.tag }}</span
                >
              </div>
            </div>
          </a> -->
        <!-- </div> -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pagesize"
          background
          layout="prev, pager, next"
          :total="news.length"
        >
        </el-pagination>
      </div>
    </div>
    <!--        end 中间部分-->
    <!--    底部-->
    <footers id="botton"></footers>
    <!--    end 底部-->
  </div>
</template>

<script>
import tabs from "@/components/tabs";
import footers from "@/components/footers";

export default {
  name: "news",
  props: [],
  components: {
    tabs,
    footers
  },

  data() {
    return {
      // height: 9999999,
      news: [],
      currentPage: 1, //初始页
      pagesize: 4 //    每页的数据
      // list: ""
    };
  },

  mounted() {
    // this.height =  window.getComputedStyle(this.$refs.element).scrollHeight;
    // let middle = this.$refs["element"];
    // this.height = middle.scrollHeight;
    var _this = this;
    // 接口
    this.$axios
      .get(
        "https://api.bluexii.cn/xiimol/v1/public/corpcmscontentlist/16/0/10",
        {
          // 还可以直接把参数拼接在url后边
        }
      )
      .then(function(res) {
        // console.log(res.data.Data);
        // _this.list = res.data.Data[0]
        _this.news = res.data.Data;
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  methods: {
    // 跳转底部
    btn() {
      // document.documentElement.scrollTop= this.height;//不需要加单位
      document.getElementById("botton").scrollIntoView();
    },
    // 跳转到详情页
    newsBtn(index) {
      console.log(index);
      var src = "/news/news_detail/" + index;

      this.$router.push(src);
      // this.$router.push({name:src})
    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange: function(size) {
      this.pagesize = size;
    },
    handleCurrentChange: function(currentPage) {
      this.currentPage = currentPage;
    }
  }
};
</script>

<style lang="less" scoped>
.main {
  // 图片
  .solution-banner-container {
    min-height: 450px;
    background-color: #000;
    background-position: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-image: url("https://bluexii.oss-cn-shanghai.aliyuncs.com/png/52aee694d98de15ff7b17fd3c15677e2.png");

    .solution-banner-content {
      margin: auto;
      padding-top: 110px;
      color: #fff;
      width: 780px;

      .solution-banner-title {
        font-size: 32px;
        line-height: 60px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      .solution-banner-desc {
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 30px;
      }
    }

    a {
      background-color: #fff;
      color: #0e57a2;
      border: 1px solid #fff;
    }
  }

  //end 图片
  //  新闻模块
  .content {
    background-color: #fff;
    padding-top: 140px;
    padding-bottom: 80px;
    max-width: 1160px;
    margin: 0 auto;

    .new-item {
      //width: 580px;
      padding: 0 0 0 0;
      display: inline-block;
      background: #fff;
      border-radius: 1px;
      margin-bottom: 39px;
      height: 350px;
    }

    .new-item:nth-child(2n-1) {
      margin-right: 40px;
    }

    a {
      text-decoration: none;
      // box-shadow: 0px 2px 8px 0px rgba(200, 200, 200, 0.5);
      border-radius: 5px;
      // width: 540px;
    }

    a:hover {
      .title {
        color: #0f2e56;
      }
    }

    .new {
      min-height: 778px;
      display: flex;
      flex-wrap: wrap;

      // .content2 {
      //   margin-left: 20px;
      // }

      .data {
        font-size: 12px;
        color: #999;
        line-height: 20px;
        margin: 16px 0;
      }

      img {
        height: 240px;
        width: 540px;
        object-fit: cover;
      }

      .title {
        font-size: 16px;
        font-weight: 600;
        color: #333;
        line-height: 28px;
        margin-bottom: 8px;
      }

      .tags {
        margin-top: 30px;

        .tag {
          padding: 5px 12px;
          font-size: 12px;
          color: #666;
          line-height: 17px;
          margin-right: 12px;
          background: #f8f8f8;
        }
      }
    }

    .el-pagination {
      margin-top: 120px;
      display: flex;
      justify-content: center;
      font-size: 14px;
    }
  }

  //  end 新闻模块

  /*纵向时间轴*/
  .time-vertical {
    list-style-type: none;
    border-left: 1px solid #707070;
    padding: 0px;
    // width: 50%;
  }

  .time-vertical li {
    height: 100px;
    position: relative;
    margin-top: 20px;
  }

  .time-vertical li a {
    display: inline-block;
    margin-left: 20px;
    margin-top: 15px;
    text-decoration: none;
    max-width: 1100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .time-vertical li b:before {
    content: "";
    position: absolute;
    top: 15px;
    left: -9px;
    width: 8px;
    height: 8px;
    border: 6px solid #c4d0f4;
    border-radius: 10px;
    background: #3c64da;
  }

  .time-vertical li span {
    position: absolute;
    color: #fff;
    top: 18px;
    left: -6px;
  }
}
</style>
